import serenescapeBanner from '../assets/serenescape-banner.png'

const Apps = () => {

    return (
        <section className="apps" id="apps">
             <div class="app-banner" id="serenescape">
                <img src={ serenescapeBanner } alt="Serenescape: Meditation Elevated" />
                {/* <a href="#" class="serenescape-link">Learn More</a> */}
                <a href="/serenescape" class="serenescape-link">Learn More</a>
             </div>
        </section>
    )
}

export default Apps;

