import scott from '../assets/scott.jpg'
import { FaLinkedinIn, FaInstagram, FaTwitter } from 'react-icons/fa'
const Home = () => {
    return (
        <section className="home" id="home">
            <div className="hero">
                <img src={scott} alt="Scott Finkelstein Headshot" />
                <h1>
                    <span style={{ color: '#8FD3E9' }}>Technologist</span><br />
                    <span style={{ color: '#71D26F' }}>Leader</span><br />
                    <span style={{ color: '#FFD465' }}>Developer</span>
                </h1>
            </div>
            <div className='social-links'>
                <a href="https://www.linkedin.com/in/scottbfinkelstein/" target='_blank' rel="noreferrer"><FaLinkedinIn /></a>
                <a href="https://www.instagram.com/scott.finkelstein/" target="_blank" rel="noreferrer"><FaInstagram /></a>
                <a href="https://twitter.com/sbf02" target='_blank' rel="noreferrer"><FaTwitter /></a>
            </div>
        </section>
    )
}

export default Home