import { useState } from "react"

const Contact = () => {
    // AWS Serverless endpoint for email submissions
    // https://0ghhk5r9ld.execute-api.us-east-1.amazonaws.com/Prod/submitForm
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const submitForm = () => {
        // e.preventDefault();
        fetch('https://0ghhk5r9ld.execute-api.us-east-1.amazonaws.com/Prod/submitForm', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({name: fullName, email: email, message: message})
        }).then(response => {
            console.log(response)
            if (response.status === 200) {
                setFormSubmitted(true)
            }
        })
    }

    const onNameChangeHandler = (e) => {
        setFullName(e.target.value)
    }

    const onEmailChangeHandler = (e) => {
        setEmail(e.target.value)
    }

    const onMessageChangeHandler = (e) => {
        setMessage(e.target.value)
    }

    return (
        <section className="contact" id="contact">
            <div class="content">
                <h2>Contact Me</h2>
                { formSubmitted ?
                    <p>Thank you for your feedback!</p> :
                    <form>
                    <div className="input-box">
                        <input type="text" value={ fullName } placeholder="Full Name" onChange={ onNameChangeHandler } />
                        <input type="email" value={ email } placeholder="Email Address" onChange={ onEmailChangeHandler } />
                    </div>
                    
                    <textarea name="" id="" cols="30" rows="10" placeholder="Your message" value={ message } onChange={ onMessageChangeHandler }></textarea>
                    <input type="button" value="Send Message" class="btn" onClick={ submitForm } />
                </form>
                }
                
            </div>
        </section>
    )
}

export default Contact