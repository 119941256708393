import serenescapeRelaxScreenshot from '../assets/serenescape-relax-iphone.png';
import serenescapeWidgetScreenshot from '../assets/widget-iphone.png';
import '../Serenescape.css';
import DownloadOnAppStore from '../assets/download-app-store.svg';
const SerenescapePage = () => {
    return (
        <div className='serenescape-wrapper'>
            <div className='serenescape-logo'></div>
            <div className='serenescape-heading'>
                <h1>Serenescape</h1>
                <h2>Meditation Elevated</h2>
            </div>
            <div className='serenescape-intro'>
                <div className='intro-copy'>
                    <p>Unlock a world of tranquility and inner peace with Serenesape, the ultimate meditation companion on your iOS device.</p>
                    <p>Immerse yourself in a serene oasis where time stands still, and let our app guide you towards a more harmonious and mindful existence. With its unique blend of soothing music and captivating colors, Serenesape will revolutionize the way you meditate.</p>
                    <a className='download-app' href="https://apps.apple.com/app/serenescape/id6448993660" target="_blank" rel="noopener noreferrer">
                        <img src={ DownloadOnAppStore } alt="Download on App Store" />
                    </a>
                </div>
                <div className='relax-screenshot'>
                    <img src={ serenescapeRelaxScreenshot } alt="Screenshot" />
                </div>
            </div>
            <div className='serenescape-premium'>
                <h3>Purchase Once, Use Always</h3>
                <div className='premium-info'>
                    <div className='widget-screenshot'>
                        <img src={ serenescapeWidgetScreenshot } alt="Screenshot" />
                    </div>
                    <div className='premium-copy'>

                        <p>One  $1.99 in-app purchase grants a lifetime of continued premium updates</p>
                        <h4>Premium Features</h4>
                        <ul>
                            <li>Three additional themes (with more coming soon)</li>
                            <li>Homescreen Widgets to quickly launch your favorite meditation</li>
                            <li>Up to 10 minutes of meditation time</li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default SerenescapePage;