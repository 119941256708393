
const About = () => {
    return (
        <section className="about" id="about">
            <div className="about-container content">
                <h2>About Me</h2>
                <p>I'm an experienced Information Technology Leader with a strong background in higher education. Over the years, I have developed an expertise in various areas of technology, including Mobile Application Development, Full Stack Web Development, Digital Transformation, and Team Building.</p>
                <p>Throughout my career, I have demonstrated a passion for using technology to drive innovation and growth, both in the workplace and beyond. I have led teams of developers and engineers, collaborating with them to create solutions that solve complex problems and enhance user experience. I am always looking for new challenges that will allow me to leverage my skills and experience to make a positive impact.</p>
                <p>In my free time, I enjoy exploring new technologies and staying up-to-date with the latest trends in the industry. I am also passionate about sharing my knowledge with others and mentoring young professionals in the field. Thank you for visiting my website, and please feel free to contact me if you have any questions or would like to discuss potential collaborations.</p>
            </div>
        </section>
    )
}

export default About